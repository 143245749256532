import Vue from 'vue';
import Router from 'vue-router';
import { config } from "../helpers";

import Home from '../views/Home.vue'
import Orders from '../views/Orders.vue'
import Order from '../views/Order.vue'
import Categories from '../views/Categories.vue'
import Tags from '../views/Tags.vue'
import BlackoutDates from '../views/BlackoutDates.vue'
import Experiences from '../views/Experiences.vue'
import Collections from '../views/Collections.vue'
import CollectionCreate from '../views/CollectionCreate.vue'
import CollectionEdit from '../views/CollectionEdit.vue'
import Carts from '../views/Carts.vue'
import Cart from '../views/Cart.vue'
import Invoices from '../views/Invoice/Invoices.vue'
import Invoice from '../views/Invoice/Invoice.vue'
import Recommendations from '../views/Recommendations/Recommendations.vue'
import Recommendation from '../views/Recommendations/Recommendation.vue'
import Users from '../views/Users/List.vue'
import User from '../views/Users/Show.vue'
import UserPurchases from '../views/UserPurchases.vue'
import UserWalletHistory from '../views/UserWalletHistory.vue'
import CorporateQuoteList from '../views/CorporateQuoteList.vue'
import Corporates from '../views/Corporates.vue'
import CorporateEdit from '../views/CorporateEdit.vue'
import Banners from '../views/Banners.vue'
import LandingPages from '../views/LandingPage/LandingPages.vue'
import LandingPageCreate from '../views/LandingPage/LandingPageCreate.vue'
import LandingPageEdit from '../views/LandingPage/LandingPageEdit.vue'
import MetaData from '../views/MetaData/MetaData.vue'
import MetaDataCreate from '../views/MetaData/MetaDataCreate.vue'
import MetaDataEdit from '../views/MetaData/MetaDataEdit.vue'
import Reviews from '../views/Reviews.vue'
import Login from '../views/auth/Login.vue'
import Promotions from '../views/Promotions.vue'
import ShowPromotion from '../views/Promotion.vue'
import PromotionCreate from '../views/PromotionCreate.vue'
import CompanyInfo from '../views/CompanyInfo.vue'
import CityManagement from "@/views/CityManagement";
import Transactions from "@/views/Transactions";
import Transaction from "@/views/Transaction";

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: Home, meta: { breadcrumb: [ { text: "Dashboard", "active": true }, ]} },
    { path: '/orders', component: Orders, meta: { breadcrumb: [ { text: "Booked List", "active": true }, ]} },
    { path: '/orders/:id', beforeEnter(to) { window.open(config('CONNECT_PANEL_ROOT') + `/orders/${to.params.id}`, '_blank').focus(); }, name: 'order.show', meta: { breadcrumb: [ { to: "/orders", text: "Booked List" }, { text: "Booking Details", "active": true }, ]} },
    { path: '/categories', component: Categories, meta: { breadcrumb: [ { text: "Categories", "active": true }, ]} },
    { path: '/tags', component: Tags, meta: { breadcrumb: [ { text: "Tags", "active": true }, ]} },
    { path: '/blackout-dates', component: BlackoutDates, meta: { breadcrumb: [ { text: "Blackout Dates", "active": true }, ]} },
    { path: '/experiences', component: Experiences, meta: { breadcrumb: [ { text: "Experiences", "active": true }, ]} },
    { path: '/collections', component: Collections, meta: { breadcrumb: [ { text: "Collections", "active": true }, ]} },
    { path: '/collections/create', component: CollectionCreate, meta: { breadcrumb: [ { to: "/collections", text: "Collections" }, { text: "Create a collection", "active": true } ]} },
    { path: '/collections/:id', component: CollectionEdit, meta: { breadcrumb: [ { to: "/collections", text: "Collections" }, { text: "Edit collection", "active": true } ]} },
    { path: '/carts', component: Carts, meta: { breadcrumb: [ { text: "Cart Management", "active": true }, ]} },
    { path: '/carts/:id', component: Cart, name: 'cart.show', meta: { breadcrumb: [ { to: "/carts", text: "Cart Management" }, { text: "Cart Details", "active": true } ]} },
    { path: '/transactions', component: Transactions, meta: { breadcrumb: [ { text: "Transaction Management", "active": true }, ]} },
    { path: '/transactions/:id', component: Transaction, name: 'transaction.show', meta: { breadcrumb: [ { to: "/transactions", text: "Transaction Management" }, { text: "Transaction Details", "active": true } ]} },
    { path: '/invoices', component: Invoices, meta: { breadcrumb: [ { text: "Invoices", "active": true }, ]} },
    { path: '/invoices/:id', component: Invoice, name: 'invoice.show', meta: { breadcrumb: [ { to: "/invoices", text: "invoices" }, { text: "Invoice details", "active": true } ]} },
    { path: '/users', component: Users, name: "users", props: true, meta: { breadcrumb: [ { text: "Users", "active": true }, ]} },
    { path: '/users/:id', component: User, name: 'user.show', meta: { breadcrumb: [ { to: "/users", text: "Users" }, { text: "User Details", "active": true } ]} },
    { path: '/users/:id/purchases', component: UserPurchases, name: 'user.purchases', meta: { breadcrumb: [ { to: "/users", text: "Users" }, { text: "Purchase History", "active": true }, ]} },
    { path: '/users/:id/wallet-history', component: UserWalletHistory, name: 'user.wallet_history', meta: { breadcrumb: [ { to: "/users", text: "Users" }, { text: "User Wallet History", "active": true } ]} },
    {path: '/recommendations', component: Recommendations, meta: { breadcrumb: [ { text: "Recommendations", "active": true }, ]}},
    {path: '/recommendations/create', component: Recommendation, name: 'recommendations.create', meta: { breadcrumb: [ { to: "/recommendations", text: "Recommendations" }, { text: "Create a recommendation", "active": true }, ]}},
    {path: '/recommendations/:id/edit', component: Recommendation, name: 'recommendations.show', meta: { breadcrumb: [ { to: "/recommendations", text: "Recommendations" }, { text: "Recommendation Details", "active": true }, ]}},
    { path: '/corporate-quote-list', component: CorporateQuoteList, meta: { breadcrumb: [ { text: "Corporate Quote List", "active": true }, ]} },
    { path: '/corporates', component: Corporates, meta: { breadcrumb: [ { to: "/corporates", text: "Corporate Management" } ] } },
    { path: '/corporates/:id/edit', component: CorporateEdit, meta: { breadcrumb: [ { to: "/corporates", text: "Corporate Management" }, { text: "Edit Corporate", "active": true } ]} },
    { path: '/banners', component: Banners, meta: { breadcrumb: [ { to: "/banners", text: "Banners" } ] } },
    { path: '/company-info', component: CompanyInfo ,name: 'company.show', meta: { breadcrumb: [ { to: "/company-info", text: "Company Info" } ] } },
    { path: '/cities', component: CityManagement , meta: { breadcrumb: [ { to: "/cities", text: "cityManagement" } ] } },
    { path: '/landing-pages', component: LandingPages, meta: { breadcrumb: [ { to: "/landing-pages", text: "Landing pages" } ] } },
    { path: '/landing-pages/create', component: LandingPageCreate, meta: { breadcrumb: [ { to: "/landing-pages", text: "Landing pages" }, { text: "Create a landing page", "active": true } ]} },
    { path: '/landing-pages/:id/edit', component: LandingPageEdit, meta: { breadcrumb: [ { to: "/landing-pages", text: "Landing pages" }, { text: "Edit landing page", "active": true } ]} },
    { path: '/meta-data', component: MetaData, meta: { breadcrumb: [ { to: "/landing-pages", text: "Landing pages" } ] } },
    { path: '/meta-data/create', component: MetaDataCreate, meta: { breadcrumb: [ { to: "/meta-data", text: "Meta data" }, { text: "Create meta data", "active": true } ]} },
    { path: '/meta-data/:type/:id/edit', component: MetaDataEdit, meta: { breadcrumb: [ { to: "/meta-data", text: "Meta data" }, { text: "Edit meta data", "active": true } ]} },
    { path: '/reviews', component: Reviews, meta: { breadcrumb: [ { to: "/reviews", text: "Reviews" } ] } },
    { path: '/login', component: Login, meta: { layout: 'empty'} },
    { path: '/promotions', component: Promotions, meta: { breadcrumb: [ { text: "Promotion codes", "active": true }, ]} },
    { path: '/promotions/:id', component: ShowPromotion, name: 'promotions.show', meta: { breadcrumb: [  { to: "/promotions", text: "Promotions" },{ text: "Promotion code detail", "active": true }, ]} },
    { path: '/promotions/create', component: PromotionCreate, name: 'promotion.create', meta: { breadcrumb: [ { to: "/promotions", text: "Promotions" }, { text: "Create a promotion", "active": true }, ]} },
    // { path: '/register', component: RegisterPage },
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register']
  const authRequired = ! publicPages.includes(to.path)
  const loggedIn = Vue.$cookies.get('dd-access-token')

  if (authRequired && ! loggedIn) {
    return next('/login')
  }

  next()
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Website Backoffice";

  next();
});
